<template>
  <div class="font-thin border-gray-400 border-b">
    Fatura {{ type }}
  </div>
  <!-- filters -->
  <div class="filters">
    <div class="filter">
      <label for="date">Data</label>
      <input type="date" id="date" v-model="filters.date" 
        :max="max_date_today" @change="getData()" />
    </div>
    <div class="filter">
      <label for="client">
        <span v-if="type === 'Shitje'">Klienti</span>
        <span v-else>Furnitori</span>
      </label>
      <!-- add icon to input --> 
      <div class="relative">
        <input type="text" class="pl-1 pr-1 py-1 rounded border">
        <span class="absolute left-2 top-1/2 transform -translate-y-1/2">
          <i class="fas fa-search fa-xs"></i>
        </span>
      </div>

    </div>
    <div class="filter">
      <label for="status">Statusi</label>
      <select id="status" v-model="filters.status">
        <option value="all">Te gjitha</option>
        <option value="paid">Paguar</option>
        <option value="unpaid">Pa paguar</option>
      </select>
    </div>

    <div class="search-button flex items-end justify-center">
      <button class="px-3 hover:text-gray-400 border-2 rounded-full py-3 px-1" @click="getData()">
        <i class="fa fa-search fa-2x"></i>
      </button>
    </div>
  </div>

  <!-- invoices table -->
  <div class="table w-full" v-if="!loading && useProcessStore().processes_invoices.length > 0">
    <table class="w-full">
      <thead>
        <tr class="text-sm uppercase">
          <th width="70px" style="text-align: center;">#</th>
          <th>Data</th>
          <th>Operatori</th>
          <th>
            <span v-if="route.params.type == 'sale'">POS</span>
            <span v-else>Furnitori</span>
          </th>
          <th>
            <span v-if="route.params.type == 'sale'">Klienti</span>
            <span v-else>Furnitori</span>
          </th>
          <th style="text-align:right">Vlera</th>
          <th style="text-align:center"><i class="fa fa-cog"></i></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="invoice in useProcessStore().processes_invoices" :key="invoice.invoice_id" class="hover:bg-gray-100">
          <td class="text-center text-gray-400">
            {{ invoice.process_number }}
          </td>
          <td>{{ invoice.completed_at }}</td>
          <td>{{ invoice.created_by }}</td>
          <td>
            <span v-if="route.params.type == 'sale'">{{ invoice.pos_name }}</span> 
            <span v-else>{{ invoice.supplier_name }}</span>
          </td>
          <td>
            <span v-if="route.params.type == 'sale'">{{ invoice.customer_name }}</span> 
            <span v-else>{{ invoice.supplier_name }}</span>
          </td>
          <td class="text-right">{{ numeral(invoice.document_price).format('0,0.00') }}</td>
          <td width="100px">
            <div class="flex justify-between w-2/3 items-center m-auto">
              <button class="btn btn-sm btn-primary hover:text-blue-500">
                <i class="fas fa-eye"></i>
              </button>
              <button class="btn btn-sm btn-primary hover:text-blue-500">
                <i class="fa fa-print"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="text-center" v-else-if="loading">
    <i class="fa fa-spinner fa-spin"></i>
  </div> 
  <div class="text-center pt-4 uppercase text-sm" v-else>
    <span class="border border-orange-200 rounded-full px-4 py-2">
      <i class="fa fa-info text-gray-400 mr-4"></i>
      Nuk ka fatura {{ type }}
    </span>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { useProcessStore } from "@/stores/process";
import { computed, reactive, onMounted, ref } from 'vue'
import Api from '@/services/Api'
var numeral = require('numeral');
const route = useRoute()

const type = computed(() => {
  switch (route.params.type) {
    case 'sale':
      return 'Shitje'
    case 'purchase':
      return 'Blerje'
    default:
      return ''
  }
})

const max_date_today = computed(() => {
  const today = new Date()
  const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
  return date
})

const loading = ref(false)

const filters = reactive({
  date: '',
  client: '',
  status: 'all',
  type: route.params.type
})

function getData(){
  if(filters.date > max_date_today.value){
    filters.date = max_date_today.value
    return
  }
  loading.value = true
  Api(true)
    .post('processes/invoices', filters)
    .then(response => {
      loading.value = false
      useProcessStore().processes_invoices = response.data
    })
}

onMounted(() => {
  // set date as current date 
  const today = new Date()
  const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
  filters.date = date
  getData()

})

</script>
<style scoped> .filters {
   display: flex;
   justify-content: space-between;
   margin-bottom: 1rem;
   padding: 0.5rem;
   /* make background grandient from gray to white top to bottom */
   background: linear-gradient(to bottom, #eee 0%, #fff 100%);
 }

 .filter {
   display: flex;
   flex-direction: column;
   border-right: 1px solid #ccc;
   padding-right: 0.5rem;
 }

 .filter label {
   font-size: 0.8rem;
   color: #666;
 }

 .filter input,
 .filter select {
   padding: 0.1rem;
   border: 1px solid #ccc;
   border-radius: 2px;
   text-align: center;
 }

 .text-center {
    text-align: center !important;
  }

  .text-right {
    text-align: right !important;
  }

  thead th {
    color: #ccc !important;
    font-weight: 400 !important;
  }
</style>
